import React from 'react'
import jackpot from '../assets/jackpot.png'

const Jackpot = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={jackpot} id="jackpot" alt="vegas" />
      </div>
      <div className="utilityContentItem">
        <p id="vegasP">
          A portion of all fees will be added to the Progressive Jackpot which continues to grow until the supply has been burned to 1,111. It will then be distributed among 25 random holders.
        </p>
        <ul id="vegasList">
          <h1 id="win">
            What can I win?
          </h1>
          <li>
            - Solana
          </li>
          <li>
            - $BLUFF
          </li>
          <li>
            - NFT's
          </li>
          <li>
            - Merch
          </li>
          <li>
            + More
          </li>
          <li>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Jackpot