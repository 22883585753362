import React from 'react'

const Roadmap = () => {
  return (
    <div className="roadmap">

      <div className="treeContainer">
        <div className="tree">
          <div style={{height: '5%'}}>
            <h1 style={{letterSpacing: '0.4ch'}}>
              ROADMAP
            </h1>
          </div>
          <div className="branch">
            <div style={{borderTop: '3px solid rgb(233, 183, 20)', width: '10%'}}>

            </div>
            <div className="roadmapInfo">
              <div className="roadmapTitle" id="filled">
                <h4>
                  Stage 1
                </h4>
              </div>
              <div className="roadmapBio">
                <ul>
                  <li id="done">
                    Creation of the official reward token of Pixel Poker Tour, $BLUFF
                  </li>
                  <li id="done">
                    Successfully complete minting process followed by Magic Eden listing
                  </li>
                  <li id="done">
                    List $BLUFF on Dexlab
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="branch">
            <div style={{borderTop: '3px solid rgb(233, 183, 20)', width: '16%'}}>

            </div>
            <div className="roadmapInfo">
              <div className="roadmapTitle" id="filled">
                <h4>
                  Stage 2
                </h4>
              </div>
              <div className="roadmapBio">
                <ul>
                  <li id="done">
                    Announce winner of Las Vegas vacation package for 2!
                  </li>
                  <li id="done">
                    Begin development of the Pixel Poker Room
                  </li>
                  <li>
                    Start promotion of main collection, Poker Pitbull Tour
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="branch">
            <div style={{borderTop: '3px solid gray', width: '23%'}}>
              
            </div>
            <div className="roadmapInfo">
              <div className="roadmapTitle">
                <h4>
                  Stage 3
                </h4>
              </div>
              <div className="roadmapBio">
                <ul>
                  <li>
                    Begin development of Pixel Poker Tour Casino platform
                  </li>
                  <li>
                    Finalize the minting process for Poker Pitbull Tour
                  </li>
                  <li>
                    Donate $25,000 to Kennel to Couch (upon mint sellout)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="branch">
            <div style={{borderTop: '3px solid gray', width: '29%'}}>

            </div>
            <div className="roadmapInfo">
              <div className="roadmapTitle">
                <h4>
                  Stage 4
                </h4>
              </div>
              <div className="roadmapBio">
                <ul>
                  <li>
                    Launch Pixel Poker Tour Casino platform!
                  </li>
                  <li>
                    Distribute casino VIP passes to PPT holders 
                  </li>
                  <li>
                    Begin casino revenue share program for verified holders
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="branch">
            <div style={{borderTop: '3px solid gray', width: '35%'}}>

            </div>
            <div className="roadmapInfo">
              <div className="roadmapTitle">
                <h4>
                  Near Future
                </h4>
              </div>
              <div className="roadmapBio">
                <ul>
                  <li>
                    Distribute progressive jackpot winnings once supply reaches 1,111
                  </li>
                  <li>
                    Release 3D NFT collection with proceeds funding the Pixel Poker DAO 
                  </li>
                  <li>
                    Launch of Pixel Poker Tour Pro Team; get paid to play!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmap
