import React from 'react'
import bluffLogo from '../assets/colorLogo.png';
import BluffPie from './BluffPie';

const Bluffcoin = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={bluffLogo} alt="$BLUFF" />
      </div>
      <div className="utilityContentItem" style={{height: '100%'}}>
       
        <ul id="vegasList" classNme="pieChart" style={{height: '90%'}}>
          <BluffPie />
          <p id="vegasP" className="bluffBio">
          Bluffcoin, or $BLUFF, is a reward token which acts as Pixel Poker Tour's native currency.
        </p>
        </ul>
        
      </div>
    </>
  )
}

export default Bluffcoin
