import React from 'react'
import royalties from '../assets/royalties.png'

const Royalties = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={royalties} id="royalties" alt="royalties" />
      </div>
      <div className="utilityContentItem">
        <p id="vegasP">
          The PPT DAO can earn royalties in a multitude of ways. These funds will be split between the Progressive Jackpot, floor sweeping and other community outlets.
        </p>
        <ul id="vegasList">
          <h1 id="win">
            What's the DAO earn?
          </h1>
          <li>
            - 50% of all minting proceeds
          </li>
          <li>
            - 90% of PPT Casino proceeds
          </li>
          <li>
            - 2.5% of all transactions
          </li>
          <li>
            - 50% of community swept NFT's
          </li>
          <li>
            - 50% stake of PPT Pro Team's winnings
          </li>
          <li>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Royalties
