import React from 'react'
import pic from '../assets/dylanpic.png'
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Team = () => {

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div className="teamPage">
      <h1 style={{color: 'white', textShadow: 'black 3px 3px 1px', letterSpacing: '0.4ch'}}>
        Team
      </h1>
      <div className="teamContainer">
        <div className="teamMember">
          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
            <img src={pic} alt=""/>
          </div>
          <h1 style={{color: '#151944', paddingLeft:'10px', textShadow: 'lightgray 1px 2px 1px', fontSize: '48px', letterSpacing: '0.3ch', width: '200px', margin: 'auto', paddingBottom: '10px', marginTop: '30px', borderRadius: '10px', background: 'rgba(148, 148, 148, 0.1)', background: 'rgb(203 195 195 / 58%)', boxShadow: 'green 0px 0px 20px 3px'}}>agxra</h1>
          <p>developer</p>
          <p>not a designer</p>
          <div style={{display: 'flex', margin: 'auto', justifyContent: 'center', gap: '20px'}}>
            <TwitterIcon id="socialIcon" onClick={() => handleClick("https://twitter.com/hiagxra")}/>
            <LinkedInIcon id="socialIcon" onClick={() => handleClick("https://linkedin.com/in/dylwilliamson")}/>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Team
