import React from 'react'
import picture from '../assets/pitty.gif'

const Pitbulls = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={picture} id="pitty" alt="pitty" />
      </div>
      <div className="utilityContentItem">
        <p id="vegasP">
          Pixel Poker Tour is actually only a stepping stone to the primary collection, Pitbull Poker Tour, which will contribute heavily to the DAO.
        </p>
        <ul id="vegasList">
          <h1 id="win">
            Pitbull Poker Tour
          </h1>
          <li>
            - Supply: 4,444
          </li>
          <li>
            - Whitelist: .22 SOL
          </li>
          <li>
            - Public Mint: .44 SOL
          </li>
          <li>
            - 5 Pixel Poker Players = Whitelist
          </li>
          <li>
            - 10 Pixel Poker Players = Free Mint
          </li>
          <li>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Pitbulls
