import React from 'react';
import gif from "../assets/Poker Punks.gif"

const Carousel = () => {
  return (
    <div className="carousel">
      <div className="carouselContainer">
        <img src={gif} alt="poker punks" />
      </div>
    </div>
  )
}

export default Carousel
