import React from 'react'
import Carousel from './Carousel'

const Mint = () => {
  return (
    <div className="mintPage">
      <Carousel />
    </div>
  )
}

export default Mint
