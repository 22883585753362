import React from 'react'
import occu from '../assets/occu.png'

const Occupations = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={occu} id="occupations" alt="occupations" />
      </div>
      <div className="utilityContentItem">
        <p id="vegasP">
          Every Pixel Poker Player has an occupation. Occupations provide a source of passive income in the form of $BLUFF.
        </p>
        <ul id="vegasList">
          <h1>
            Salaries
          </h1>
          <li>
            <strong style={{color: 'orange'}}>Unemployed: </strong>1/day
          </li>
          <li>
            <strong style={{color: 'orange'}}>McDonald's: </strong>2/day
          </li>
          <li>
            <strong style={{color: 'orange'}}>Pro Player: </strong>3/day
          </li>
          <li>
            <strong style={{color: 'orange'}}>Pot Farmer: </strong>4/day
          </li>
          <li>
            <strong style={{color: 'orange'}}>Hedge Fund Baby: </strong>6/day
          </li>
          <li>
            <strong style={{color: 'orange'}}>Pack Runner: </strong>8/day
          </li>
          <li>
            <strong style={{color: 'orange'}}>Crypto Billionaire: </strong>10/day
          </li>
        </ul>
      </div>
    </>
  )
}

export default Occupations
