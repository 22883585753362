import React from 'react'

const Concept = () => {
  return (
    <div style={{height: 'calc(100% - 200px)'}}>
      <h1 style={{letterSpacing: '.4ch', color: 'white', textShadow: 'black 3.5px 3.5px 1px'}}>
        CONCEPT
      </h1>
      <p style={{fontSize: '24px', letterSpacing: '0.5ch', opacity: '0.5', color: 'white', textShadow: 'black 2.5px 2.5px 1px'}}>
       WORTHLESS ART + PRICELESS UTILITY
      </p>
      <div className="conceptContainer">
        <div className="conceptWrap">
          <div className="conceptItem">
            <h1>
              Offer diamond hands a way to earn with minimal downside risk
            </h1>
          </div>
          <div className="conceptItem">
            <h1>
              Give a lucky minter a 1 week Las Vegas vacation for 2 at The Bellagio
            </h1>
          </div>
          <div className="conceptItem">
            <h1>
              Funds used to build casino & cardroom earning holders passive income
            </h1>
          </div>
        </div>
        <div className="conceptWrap">
          <div className="conceptItem">
            <h1>
              Progressive floor sweeping which burns supply down to 1,111
            </h1>
          </div>
          <div className="conceptItem">
            <h1>
              Incentivize delisting by airdropping NFT's & SOL to holders

              
            </h1>
          </div>
          <div className="conceptItem">
            <h1>
              Compounding approach to increasing the floor price
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Concept
