import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import logo from "./assets/gladlogo.png";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";
import Roadmap from "./pages/Roadmap";
import Utility from "./pages/Utility";
import Mint from "./pages/Mint"
import Concept from "./pages/Concept";
import Team from "./pages/Team";
import TwitterIcon from '@mui/icons-material/Twitter';
import DiscordIcon from './assets/kisspng-email-business-hotel-florida-gulf-coast-university-discord-logo-5b44a86702d762.8333540615312262150117.png';

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const handleClick = (url: string) => {
    window.open(url, '_blank')
  }

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }
      

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>
      <div className="page" id="Mint">
      <div className="nav">
        <div className="navContainer">
          <div className="navItem">
            <a id="mintLink" href="#Mint">
              Mint
            </a>
          </div>
          <div className="navItem">
            <a id="aboutLink" href="#Concept">
              Concept
            </a>
          </div>
          <div className="navItem">
            <a id="roadmapLink" href="#Roadmap">
              Roadmap
            </a>
          </div>
          <div className="navItem">
            <a id="benefitsLink" href="#Utility">
              Utility
            </a>
          </div>
          <div className="navItem">
            <a id="fiveLink" href="#Team">
              Team
            </a>
          </div>
        </div>
      </div>
      <h1 className="mainTitle">
        Pixel Poker Tour
      </h1>
      <div style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px', justifyContent: 'center', gap: '20px'}}>
          <TwitterIcon id="socialIcon" onClick={() => handleClick("https://twitter.com/pixelpokertour")}/>
          <img src={DiscordIcon} id="socialIcon" onClick={() => handleClick("https://discord.gg/qcUNqwEXyE")}/>
        </div>
      <div className="infoWrapper">
        <h3>
          Mint Price: FREE
        </h3>
        <div className="mintInfo">
        {wallet && <p>Remaining: {`${itemsRemaining} / ${itemsAvailable}`}</p>}
          {/* <MintContainer id="mintContainer">
            {!wallet ? (
              <ConnectButton id="connectWallet">Connect Wallet</ConnectButton>
            ) : (
              <MintButton
                disabled={isSoldOut || isMinting || !isActive}
                onClick={onMint}
                variant="contained"
              >
                {isSoldOut ? (
                  "SOLD OUT"
                ) : isActive ? (
                  isMinting ? (
                    <CircularProgress />
                  ) : (
                    "MINT"
                  )
                ) : (
                  <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsActive(true)}
                    onComplete={() => setIsActive(true)}
                    renderer={renderCounter}
                  />
                )}
              </MintButton>
            )}
          </MintContainer> */}
          <a href="https://magiceden.io/marketplace/pixel_poker_tour">Live on Magic Eden</a>
        </div>
        <h3>
          Supply: 4,444
        </h3>
      </div>
      <div>
        {/* {wallet && (
          <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
        )} */}

        {/* {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>} */}

        {/* {wallet && <p>Total Supply: {itemsAvailable}</p>} */}

        {/* {wallet && <p>Minted: {itemsRedeemed}</p>} */}

        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
        </div>
        <Mint />
      </div>
      <div className="page" id="Concept">
        <Concept />
      </div>
      <div className="page" id="Roadmap">
        <Roadmap />
      </div>
      <div className="page" id="Utility">
        <Utility />
      </div>
      <div className="page" id="Team">
        <Team />
      </div>
    </main>
    
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
