import React from 'react'
import pptCasino from '../assets/pptCasino.png'

const Casino = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={pptCasino} id="pptCasino" alt="$BLUFF" />
      </div>
      <div className="utilityContentItem">
        <p id="vegasP">
          PPT Casino will be a primary source of income for the community. DAO members earn casino profits DAILY, as well as Club PPT VIP passes with special perks varying by tier.
        </p>
        <ul id="vegasList">
          <h1 id="win">
            Details
          </h1>
          <li>
            - Casino (Dice, Roulette, Blackjack, etc.)
          </li>
          <li>
            - Cardroom (NLH, PLO, 7-Card Stud, etc.)
          </li>
          <li>
            - Club PPT: 5 tiers of VIP with special perks
          </li>
          <li>
            - Rakeback on all wagers in form of $BLUFF
          </li>
          <li>
            - Passive income for 100% of DAO members
          </li>
          <li>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Casino
