import React from 'react'
import bellagio from '../assets/bellagio.jpeg'

const Vegas = () => {
  return (
    <>
      <div className="utilityContentItem">
        <img src={bellagio} id="vegas" alt="vegas" />
      </div>
      <div className="utilityContentItem">
        <p id="vegasP">
          One lucky holder will win a trip for 2 to The Bellagio, a luxury hotel & casino in Las Vegas.
        </p>
        <ul id="vegasList">
          <h1>
            What's included?
          </h1>
          <li>
            - 6 Days/5 Nights of hotel accommodations
          </li>
          <li>
            - $1,000 cash
          </li>
          <li>
            - Bus tour of the South Rivera of the Grand Canyon
          </li>
          <li>
            - Luxury sports car tour
          </li>
          <li>
            - Helicopter tour of the Las Vegas Strip
          </li>
        </ul>
      </div>
    </>
  )
}

export default Vegas