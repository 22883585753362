import React, { useState } from 'react';
import Bluffcoin from './Bluffcoin';
import Vegas from './Vegas';
import Jackpot from './Jackpot';
import Casino from './Casino';
import Pitbulls from './Pitbulls';
import Royalties from './Royalties';
import Occupations from './Occupations';

const Utility = () => {
  
  const [selected, setSelected] = React.useState("$BLUFF")
  const handleClick = (val) => {
    setSelected(val);
  }


  return (
    <div className="util">
      <h1 style={{letterSpacing: '.4ch', color: 'black', textShadow: 'white 2px 2.5px 1px'}}>
        UTILITY
      </h1>
      <div className="utilityContainer">
        <div className="utilityNav">
          <button onClick={() => handleClick("$BLUFF")} id={selected === "$BLUFF" ? "selected" : ""} className="utilityNavItem">
            <h3>
              $BLUFF
            </h3>
          </button>
          <button onClick={() => handleClick("Pitbulls")} id={selected === "Pitbulls" ? "selected" : ""} className="utilityNavItem">
            <h3>
              Pitbulls
            </h3>
          </button>
          <button onClick={() => handleClick("Casino")} id={selected === "Casino" ? "selected" : ""} className="utilityNavItem">
            <h3>
              Casino
            </h3>
          </button>
          <button onClick={() => handleClick("Royalties")} id={selected === "Royalties" ? "selected" : ""} className="utilityNavItem">
            <h3>
              Royalties
            </h3>
          </button>
          <button onClick={() => handleClick("Occupations")} id={selected === "Occupations" ? "selected" : ""} className="utilityNavItem">
            <h3>
              Occupations
            </h3>
          </button>
          <button onClick={() => handleClick("Vegas")} id={selected === "Vegas" ? "selected" : ""} className="utilityNavItem">
            <h3>
              Vegas
            </h3>
          </button>
          <button onClick={() => handleClick("Jackpot")} id={selected === "Jackpot" ? "selected" : ""} className="utilityNavItem">
            <h3>
              Jackpot
            </h3>
          </button>
        </div> 
        <div className="utilityContent">
          {selected === "$BLUFF" && <Bluffcoin />}
          {selected === "Occupations" && <Occupations />}
          {selected === "Vegas" && <Vegas />}
          {selected === "Casino" && <Casino />}
          {selected === "Royalties" && <Royalties />}
          {selected === "Jackpot" && <Jackpot />}
          {selected === "Pitbulls" && <Pitbulls />}
        </div>
      </div>
    </div>
  )
}

export default Utility
