import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class BluffPie extends Component {
  getOption = () => {
    return {
      title: {
        text: "Total Supply: 1,000,000,000",
        x: "center",
        
        textStyle: {
          fontSize: 24,
          fontFamily: 'Chewy',
          color: 'white'
        },
      },
      textStyle: {
        fontSize: 12,
        fontFamily: 'Chewy',
        color: 'black'
      },
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return params.name + ": " + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ` (${params.percent}%)`
        }
      },
      color: ["orange", "darkgreen", "#5a5a5a", "white", "#640b2d", "black"],
      
      labelLine: {
        lineStyle: {
          color: 'white'
        },
        smooth: 0.2,
        length: 30,
        length2: 20
      },
      label: {
        color: 'black',
        fontSize: 18,
      },
      series: [
        {
          name: "Allocation",
          type: "pie",
          radius: "75%",
          center: ["50%", "60%"],
          data: [
            { value: 350000000, name: "Cardroom" },
            { value: 150000000, name: "Casino" },
            { value: 150000000, name: "Occupations" },
            { value: 100000000, name: "Team" },
            { value: 150000000, name: "Marketing" },
            { value: 100000000, name: "Liquidity Pool" },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  render() {
    return (
      <>
        <ReactEcharts style={{ height: "100%", fontFamily: 'Chewy' }} option={this.getOption()} />
      </>
    )
  }
}
export default BluffPie
